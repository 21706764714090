import {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import classNames from "classnames";

export type ModalSizeTypes =
  | "small"
  | "big"
  | "bigger"
  | "biggest"
  | "full"
  | "side"
  | "over_fullscreen"
  | "bottom";
export interface ModalProps {
  className?: string;
  trigger?: ReactNode;
  show?: boolean;
  toggleShow?: (value: boolean) => void;
  size?: ModalSizeTypes;
  disableClose?: boolean;
  onInteractOutside?: () => void;
  onEscapeKeyDown?: () => void;
  withoutContainer?: boolean;
  darkerOverlay?: boolean;
  children?: ReactNode;
  secondModalContent?: ReactNode;
}

const Modal: FC<ModalProps> = ({
  className,
  trigger,
  show,
  toggleShow,
  size,
  disableClose = false,
  onInteractOutside,
  onEscapeKeyDown,
  withoutContainer = false,
  darkerOverlay = false,
  children,
  secondModalContent,
}) => {
  const keyPressedFn = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Esc" || e.key === "Escape") {
        onEscapeKeyDown
          ? onEscapeKeyDown()
          : show && toggleShow && toggleShow(false);
      }
    },
    [onEscapeKeyDown, toggleShow, show]
  );

  const onClickOutside: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();
      if (disableClose) return;

      onInteractOutside ? onInteractOutside() : toggleShow && toggleShow(false);
    },
    [disableClose, onInteractOutside, toggleShow]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPressedFn, false);
    return () => {
      document.removeEventListener("keydown", keyPressedFn, false);
    };
  }, [keyPressedFn]);

  if (!trigger && !show) return null;

  return (
    <DialogPrimitive.Root modal open={show} onOpenChange={() => null}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay
          className={classNames(
            "z-50 fixed inset-0",
            darkerOverlay ? "bg-black-ink/50" : "bg-black-ink/25"
          )}
          onClick={onClickOutside}
        />

        <div
          className={classNames(
            "z-50 fixed inset-0 font-roboto flex items-center p-6",
            size === "bottom" && "!items-end !p-0",
            size === "side" ? "justify-end" : "justify-center",
            size === "side" && "!p-0",
            size === "over_fullscreen" && "!p-0 mt-12",
            secondModalContent && "flex-col"
          )}
        >
          <DialogPrimitive.Content
            className={classNames(
              className,
              !secondModalContent && !withoutContainer && "bg-white",
              size === "bottom" && "!rounded-b-none",
              size !== "side" && !withoutContainer && "rounded-3xl",
              "relative",
              size === "over_fullscreen" &&
                !withoutContainer &&
                "rounded-b-none",
              size &&
                {
                  small: "w-full sm:w-96",
                  big: "w-full sm:w-[700px]",
                  bigger: "w-full sm:w-[820px]",
                  biggest: "w-full sm:w-[1030px]",
                  side: "h-screen w-screen sm:w-105 shadow-thin",
                  full: "w-full h-full flex flex-col",
                  over_fullscreen: "w-screen h-screen max-w-md",
                  bottom: "w-full sm:w-[700px] max-h-[50vh]",
                }[size]
            )}
            onClick={(e) => (withoutContainer ? onClickOutside(e) : undefined)}
          >
            {secondModalContent ? (
              <>
                <div
                  className={classNames(
                    "w-full",
                    !withoutContainer && "bg-white rounded-3xl"
                  )}
                >
                  {children}
                </div>
                <div
                  className={classNames(
                    "mt-4 w-full",
                    !withoutContainer && "bg-white rounded-3xl"
                  )}
                >
                  {secondModalContent}
                </div>
              </>
            ) : (
              children
            )}
          </DialogPrimitive.Content>
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default Modal;
